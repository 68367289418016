import axios from "./request"

export function GetQuestionList(body){
  return axios({
      url: "/Standard/GetQuestionList",
      method: "get",
      params: body
  })
}
export const sendRegisterCode=(body)=>{
  return axios({
      url: "/SMS/SendSMSForBanshou",
      method: "get",
      params: body
  })
}
export const providerReg=(body)=>{
  return axios({
      url: "/provider/Register",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export const wxLogin=(body)=>{
  return axios({
      url: "/user/Mplogin",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function userNameLogin(body){
  return axios({
      url: "/user/clogin",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function priceMakerLogin(body){
  return axios({
      url: "/PriceMaker/MpBind",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function validToken(body){
  return axios({
      url: "/identity/ValidToken",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,
      params:body
  })
}
export function userLogout(body){
  return axios({
      url:"/user/UnbindOpenId",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  });
}