import axios from 'axios'
export const codeToOpenid=(body)=>{
  const service = axios.create({
      baseURL:'/mpwx',
      timeout:30000,
      headers:{}
  })
  return service({
      method:'post',
      url:'/wxAuth/codeToOpenid',
      data:body
  })
}
export function getWXinfo(body){
  const service = axios.create({
      baseURL:'/mpwx',
      timeout:30000,
      headers:{
          'wx-client-href':body.url
      }
  })
  service.interceptors.response.use(function (response) {
      return response.data
  })
  return service({
      method:'get',
      url:'/wxAuth/getShareSignature',
      params:body
  })
}
export function MpTenPay(body){
    return axios({
        url: "/MpTenPayV3/JsApi",
        headers:{'Content-Type': 'application/json'},
        method: "get",
        withCredentials:true,   
        params: body
    })
}